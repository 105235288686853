var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "payment-methods" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "title py-2" }, [
              _vm._v("Payment Methods"),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "d-flex", attrs: { md: "auto", cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "autopay-button my-auto",
                  attrs: { size: "sm" },
                  on: { click: _vm.setupAutopay },
                },
                [_vm._v("Setup Autopay")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _c("payment-method-options", {
        attrs: {
          "is-show-actions": true,
          "is-show-pay-agreement": false,
          "is-show-select-radio": false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }