<template>
  <b-container class="payment-methods">
    <b-row>
      <b-col>
        <div class="title py-2">Payment Methods</div>
      </b-col>
      <b-col md="auto" cols="12" class="d-flex">
        <b-button class="autopay-button my-auto" size="sm" @click="setupAutopay">Setup Autopay</b-button>
      </b-col>
    </b-row>
    <hr>
    <payment-method-options
      :is-show-actions="true"
      :is-show-pay-agreement="false"
      :is-show-select-radio="false"
    />
  </b-container>
</template>

<script>
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions'
import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'PaymentMethods',
  components: { PaymentMethodOptions },
  mixins: [paymentMethodsMixin],
  data() {
    return {}
  },
  beforeDestroy () {
    this.setIsExpiredCardConversion(false)
  },
  methods: {
    setupAutopay(){
      this.logAutopaySetupInteraction()
      this.$router.push('/services')
    },
    logAutopaySetupInteraction() {
      const interaction = {
        name: 'payment-methods-page',
          action: 'setup-autopay-clicked',
      }

      createOrFindClientInteractionLog({
        category: 'autopay-interaction',
        subCategory: 'manage-autopay',
        interaction,
        incompleteLogFromToday: true,
      }).catch() // suppress any api errors
    },
  },
}
</script>

<style lang="scss">

.payment-methods {
  @media only screen and (max-width: 991px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  hr {
    border-top: 1px solid #98999B;
  }

  .title{
    font-size: 2rem;
    font-weight: bold;
  }

  .autopay-button {
    background-color: #000864;
    color: white;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
  }
}
</style>
